html {
  font-size: 14.5px;

  @media only screen and (min-width: 650px) {
    font-size: 24px;
  }
}

* {
  text-rendering: optimizeLegibility;
  font-smoothing: antialiased;
}

a {
  color: black;
  @extend .link--underlined;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

h1, h2, h3, h4, h5, h6, p {
  font-weight: normal;
  margin: 0;
  padding: 0;
  font-size: 1em;
  line-height: 1.4;

  & + * {
    margin-top: 0.5em;
  }
}
