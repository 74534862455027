.ma-container {
  height: auto;
  overflow: hidden;
}

.ma-grid {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  display: grid;
  grid-template-columns: repeat(#{$gridNumColMobile},1fr);
  grid-template-rows: calc((100vw - var(--scrollbar-width)) / #{$gridNumColMobile} * var(--logoPropotion)) 1fr;
  background-color: #F4E4CD;

  @media only screen and (min-width: 900px) {
    grid-template-columns: repeat(#{$gridNumCol},1fr);
    grid-template-rows: calc((100vw - var(--scrollbar-width)) / #{$gridNumCol} * var(--logoPropotion)) 1fr;
  }
}

.ma-grid__logo {
  background-color: #F4E4CD;

  &.ma-grid__logo--flip svg g {
    transform-origin: 50% 50%;
    transform: scaleY(-1);
  }
}


//logo width = (100vw - var(--scrollbar-width)) / #{$gridNumCol};
.ma-sheet {
  pointer-events: none;
  position: relative;

  &__container {
    position: relative;
    width: 100%;
    min-height: calc(100vh - ((100vw - var(--scrollbar-width)) / #{$gridNumColMobile} * var(--logoPropotion) * 1));
    padding-top: calc((100vw - var(--scrollbar-width)) / #{$gridNumColMobile} * var(--logoPropotion) * 1);
    padding-bottom: calc((100vw - var(--scrollbar-width)) / #{$gridNumColMobile} * var(--logoPropotion) * 5);
    z-index: 100;

    @media only screen and (min-width: 900px) {
      min-height: calc(100vh - ((100vw - var(--scrollbar-width)) / #{$gridNumCol} * var(--logoPropotion) * 1));
      padding-top: calc((100vw - var(--scrollbar-width)) / #{$gridNumCol} * var(--logoPropotion) * 1);
      padding-bottom: calc((100vw - var(--scrollbar-width)) / #{$gridNumCol} * var(--logoPropotion) * 5);
      padding-left: calc((100vw - var(--scrollbar-width)) / #{$gridNumCol} * 1);
      padding-right: calc((100vw - var(--scrollbar-width)) / #{$gridNumCol} * 1);
    }
  }

  &__box {
    pointer-events: initial;
    background-color: white;
  }

  &__content {
    padding: 20px;

    @media only screen and (min-width: 900px) {
      padding: calc(((100vw - var(--scrollbar-width)) / #{$gridNumCol}) / 2);
      padding-top: calc(((100vw - var(--scrollbar-width)) / #{$gridNumCol} * var(--logoPropotion)) / 2 - 0.3em);
    }
  }
}

.ma-sheet__body {

}

.ma-sheet__heading {
  max-width: 1030px;

  p + p {
    margin-top: 1em;

    @media only screen and (min-width: 650px) {
      margin-top: 0.5em;
    }
  }
}

.ma-sheet__projects {
  margin-bottom: 2em;
  margin-top: 3em;

  @media only screen and (min-width: 650px) {
    margin-top: 4em;
  }
}


$rosesPerLogo : $gridNumCol*2;

.ma-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  pointer-events: initial;
  text-align: center;
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2.5em 0;
  height: calc((100vw - var(--scrollbar-width)) / #{$gridNumColMobile} * var(--logoPropotion)*3);

  @media only screen and (min-width: 900px) {
    padding: 1.5em 0;
    height: calc((100vw - var(--scrollbar-width)) / #{$gridNumCol} * var(--logoPropotion)*3);
  }

  @media only screen and (min-width: 1300px) {
    height: calc((100vw - var(--scrollbar-width)) / #{$gridNumCol} * var(--logoPropotion)*2);
  }

  @media only screen and (min-width: 1400px) {
    padding: 2em 0;
  }

  &__links {
  }


  a {
    color: white;

    &:hover {
      color: var(--hoverColor);
    }
  }

  &__rose svg {
    object-fit: contain;
    width: calc((100vw - var(--scrollbar-width)) / #{$gridNumColMobile} * var(--logoPropotion)*1.5 - 1em);
    height: calc((100vw - var(--scrollbar-width)) / #{$gridNumColMobile} * var(--logoPropotion)*1.5 - 1em);
    margin-left: auto;
    margin-right: auto;

    @media only screen and (min-width: 900px) {
      width: calc((100vw - var(--scrollbar-width)) / #{$gridNumCol} * var(--logoPropotion)*1.5 - .5em);
      height: calc((100vw - var(--scrollbar-width)) / #{$gridNumCol} * var(--logoPropotion)*1.5 - .5em);
    }

    @media only screen and (min-width: 1300px) {
      width: calc((100vw - var(--scrollbar-width)) / #{$gridNumCol} * var(--logoPropotion) - .5em);
      height: auto;
      object-fit: initial;
    }
  }
}
