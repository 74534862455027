body {
  position: relative;
  font-family: "Syncro Mono", monospace;
  background-color: #F4E4CD;
  color: black;
  // display: none;

  --logoPropotion: 1.1988;
  --logoLegsWidth: 0.3074;
  // --hoverColor: #3663E4;

  --rosePropotion: 0.9;

  // &.page-ready {
  //   display: block;
  // }
}

::selection {
  color: white;
  background: black;
}
