*, :before, :after {
  box-sizing: border-box;
  background-repeat: no-repeat;
}

:before, :after {
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
  vertical-align: inherit;
}

:where(:root) {
  cursor: default;
  overflow-wrap: break-word;
  tab-size: 4;
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: 100%;
  line-height: 1.5;
}

:where(body) {
  margin: 0;
}

:where(h1) {
  margin: .67em 0;
  font-size: 2em;
}

:where(dl, ol, ul) :where(dl, ol, ul) {
  margin: 0;
}

:where(hr) {
  color: inherit;
  height: 0;
}

:where(nav) :where(ol, ul) {
  padding: 0;
  list-style-type: none;
}

:where(nav li):before {
  content: "​";
  float: left;
}

:where(pre) {
  font-family: monospace;
  font-size: 1em;
  overflow: auto;
}

:where(abbr[title]) {
  text-decoration: underline dotted;
}

:where(b, strong) {
  font-weight: bolder;
}

:where(code, kbd, samp) {
  font-family: monospace;
  font-size: 1em;
}

:where(small) {
  font-size: 80%;
}

:where(audio, canvas, iframe, img, svg, video) {
  vertical-align: middle;
}

:where(iframe) {
  border-style: none;
}

:where(svg:not([fill])) {
  fill: currentColor;
}

:where(table) {
  border-collapse: collapse;
  border-color: inherit;
  text-indent: 0;
}

:where(button, input, select) {
  margin: 0;
}

:where(button, [type="button" i], [type="reset" i], [type="submit" i]) {
  -webkit-appearance: button;
}

:where(fieldset) {
  border: 1px solid #a0a0a0;
}

:where(progress) {
  vertical-align: baseline;
}

:where(textarea) {
  resize: vertical;
  margin: 0;
}

:where([type="search" i]) {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

::-webkit-input-placeholder {
  color: inherit;
  opacity: .54;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

:where(dialog) {
  color: #000;
  background-color: #fff;
  border: solid;
  width: fit-content;
  height: fit-content;
  margin: auto;
  padding: 1em;
  position: absolute;
  left: 0;
  right: 0;
}

:where(dialog:not([open])) {
  display: none;
}

:where(details > summary:first-of-type) {
  display: list-item;
}

:where([aria-busy="true" i]) {
  cursor: progress;
}

:where([aria-controls]) {
  cursor: pointer;
}

:where([aria-disabled="true" i], [disabled]) {
  cursor: not-allowed;
}

:where([aria-hidden="false" i][hidden]) {
  display: initial;
}

:where([aria-hidden="false" i][hidden]:not(:focus)) {
  clip: rect(0, 0, 0, 0);
  position: absolute;
}

@font-face {
  font-family: Syncro Mono;
  src: url("SyncroMono-Web.2022b3dd.woff") format("woff"), url("SyncroMono-Web.bf081c1b.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

html {
  font-size: 14.5px;
}

@media only screen and (width >= 650px) {
  html {
    font-size: 24px;
  }
}

* {
  text-rendering: optimizeLegibility;
  font-smoothing: antialiased;
}

a {
  color: #000;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  padding: 0;
  font-size: 1em;
  font-weight: normal;
  line-height: 1.4;
}

h1 + *, h2 + *, h3 + *, h4 + *, h5 + *, h6 + *, p + * {
  margin-top: .5em;
}

body {
  color: #000;
  --logoPropotion: 1.1988;
  --logoLegsWidth: .3074;
  --rosePropotion: .9;
  background-color: #f4e4cd;
  font-family: Syncro Mono, monospace;
  position: relative;
}

::selection {
  color: #fff;
  background: #000;
}

.link--underlined:not(.link--not-underlined), a:not(.link--not-underlined) {
  border-bottom: 1px solid #000;
}

.ua-mobile-iphone.ua-safari .link--underlined:not(.link--not-underlined), .ua-mobile-iphone.ua-safari a:not(.link--not-underlined), .ua-desktop-macintosh.ua-chrome .link--underlined:not(.link--not-underlined), .ua-desktop-macintosh.ua-chrome a:not(.link--not-underlined), .ua-desktop-macintosh.ua-safari .link--underlined:not(.link--not-underlined), .ua-desktop-macintosh.ua-safari a:not(.link--not-underlined) {
  background-image: linear-gradient(#fff 1%, #000 1%);
  background-position: 0 1.1em;
  background-repeat: repeat-x;
  background-size: 1px 1px;
  border-bottom: 0;
}

@media only screen and (width >= 650px) {
  .ua-mobile-iphone.ua-safari .link--underlined:not(.link--not-underlined), .ua-mobile-iphone.ua-safari a:not(.link--not-underlined), .ua-desktop-macintosh.ua-chrome .link--underlined:not(.link--not-underlined), .ua-desktop-macintosh.ua-chrome a:not(.link--not-underlined), .ua-desktop-macintosh.ua-safari .link--underlined:not(.link--not-underlined), .ua-desktop-macintosh.ua-safari a:not(.link--not-underlined) {
    background-size: 1px 2px;
  }
}

.link--underlined:not(.link--not-underlined):hover, a:not(.link--not-underlined):hover {
  border-color: var(--hoverColor);
}

.ua-mobile-iphone.ua-safari .link--underlined:not(.link--not-underlined):hover, .ua-mobile-iphone.ua-safari a:not(.link--not-underlined):hover, .ua-desktop-macintosh.ua-chrome .link--underlined:not(.link--not-underlined):hover, .ua-desktop-macintosh.ua-chrome a:not(.link--not-underlined):hover, .ua-desktop-macintosh.ua-safari .link--underlined:not(.link--not-underlined):hover, .ua-desktop-macintosh.ua-safari a:not(.link--not-underlined):hover {
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(1%, white), color-stop(1%, var(--hoverColor)));
  background-image: linear-gradient(white 1%, var(--hoverColor) 1%);
  color: #fff;
  background-position: 0 0;
  background-size: 1px 1.17em;
}

.nowrap {
  white-space: nowrap;
}

.supperscript {
  vertical-align: super;
  padding-left: .5em;
  font-size: 60%;
}

.ma-container {
  height: auto;
  overflow: hidden;
}

.ma-grid {
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: calc((100vw - var(--scrollbar-width)) / 6 * var(--logoPropotion)) 1fr;
  background-color: #f4e4cd;
  display: grid;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

@media only screen and (width >= 900px) {
  .ma-grid {
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: calc((100vw - var(--scrollbar-width)) / 10 * var(--logoPropotion)) 1fr;
  }
}

.ma-grid__logo {
  background-color: #f4e4cd;
}

.ma-grid__logo.ma-grid__logo--flip svg g {
  transform-origin: 50%;
  transform: scaleY(-1);
}

.ma-sheet {
  pointer-events: none;
  position: relative;
}

.ma-sheet__container {
  width: 100%;
  min-height: calc(100vh - (100vw - var(--scrollbar-width)) / 6 * var(--logoPropotion) * 1);
  padding-top: calc((100vw - var(--scrollbar-width)) / 6 * var(--logoPropotion) * 1);
  padding-bottom: calc((100vw - var(--scrollbar-width)) / 6 * var(--logoPropotion) * 5);
  z-index: 100;
  position: relative;
}

@media only screen and (width >= 900px) {
  .ma-sheet__container {
    min-height: calc(100vh - (100vw - var(--scrollbar-width)) / 10 * var(--logoPropotion) * 1);
    padding-top: calc((100vw - var(--scrollbar-width)) / 10 * var(--logoPropotion) * 1);
    padding-bottom: calc((100vw - var(--scrollbar-width)) / 10 * var(--logoPropotion) * 5);
    padding-left: calc((100vw - var(--scrollbar-width)) / 10 * 1);
    padding-right: calc((100vw - var(--scrollbar-width)) / 10 * 1);
  }
}

.ma-sheet__box {
  pointer-events: initial;
  background-color: #fff;
}

.ma-sheet__content {
  padding: 20px;
}

@media only screen and (width >= 900px) {
  .ma-sheet__content {
    padding: calc((100vw - var(--scrollbar-width)) / 10 / 2);
    padding-top: calc((100vw - var(--scrollbar-width)) / 10 * var(--logoPropotion) / 2 - .3em);
  }
}

.ma-sheet__heading {
  max-width: 1030px;
}

.ma-sheet__heading p + p {
  margin-top: 1em;
}

@media only screen and (width >= 650px) {
  .ma-sheet__heading p + p {
    margin-top: .5em;
  }
}

.ma-sheet__projects {
  margin-top: 3em;
  margin-bottom: 2em;
}

@media only screen and (width >= 650px) {
  .ma-sheet__projects {
    margin-top: 4em;
  }
}

.ma-footer {
  pointer-events: initial;
  text-align: center;
  width: 100%;
  height: calc((100vw - var(--scrollbar-width)) / 6 * var(--logoPropotion) * 3);
  background-color: #000;
  flex-direction: column;
  justify-content: space-between;
  padding: 2.5em 0;
  display: flex;
  position: absolute;
  bottom: 0;
}

@media only screen and (width >= 900px) {
  .ma-footer {
    height: calc((100vw - var(--scrollbar-width)) / 10 * var(--logoPropotion) * 3);
    padding: 1.5em 0;
  }
}

@media only screen and (width >= 1300px) {
  .ma-footer {
    height: calc((100vw - var(--scrollbar-width)) / 10 * var(--logoPropotion) * 2);
  }
}

@media only screen and (width >= 1400px) {
  .ma-footer {
    padding: 2em 0;
  }
}

.ma-footer a {
  color: #fff;
}

.ma-footer a:hover {
  color: var(--hoverColor);
}

.ma-footer__rose svg {
  object-fit: contain;
  width: calc((100vw - var(--scrollbar-width)) / 6 * var(--logoPropotion) * 1.5 - 1em);
  height: calc((100vw - var(--scrollbar-width)) / 6 * var(--logoPropotion) * 1.5 - 1em);
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (width >= 900px) {
  .ma-footer__rose svg {
    width: calc((100vw - var(--scrollbar-width)) / 10 * var(--logoPropotion) * 1.5 - .5em);
    height: calc((100vw - var(--scrollbar-width)) / 10 * var(--logoPropotion) * 1.5 - .5em);
  }
}

@media only screen and (width >= 1300px) {
  .ma-footer__rose svg {
    width: calc((100vw - var(--scrollbar-width)) / 10 * var(--logoPropotion)  - .5em);
    object-fit: initial;
    height: auto;
  }
}
/*# sourceMappingURL=index.461de5dc.css.map */
